@import "./custom.scss";
@import "~antd/dist/antd.css";

html {
  font-size: 120%;
}
.variant-item {
  width: 50px;
  height: 50px;
  cursor: pointer;
}
a.test-link {
  font-weight: bold;
}
a.test-link:hover {
  color: #fff !important;
}

.question-title {
  font-size: 2rem;
}

.question-item-text {
  font-size: 1.5rem;
  font-weight: 500;
}

.img-waiting {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.fs-24 {
  font-size: 1.5rem;
}

.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
.site-layout .site-layout-background {
  background: #fff;
}

.ant-form-item-control-input-content {
  .radio--group {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
.admin-header {
  z-index: 90;
  right: 0;
  top: 0;
}
